import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VForm,{ref:"form",staticClass:"companyProfileForm w-100",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VRow,{staticClass:"w-100",attrs:{"no-gutters":"","justify":"center"}},[_c(VCol,{staticClass:"mb-10",attrs:{"cols":"12","md":"12"}},[_c('CustomInput',{attrs:{"type":"avatar","rules":[],"label":"Profile Photo*","name":"headshot_file","config":{
            accept: 'image/*'
          },"showFileName":false,"clearMediaConfirmation":""},on:{"clearFile":_vm.clearFile,"input":_vm.input},model:{value:(_vm.formValues.headshot_file),callback:function ($$v) {_vm.$set(_vm.formValues, "headshot_file", $$v)},expression:"formValues.headshot_file"}})],1),_c(VCol,{attrs:{"cols":"12","md":"12"}},[_c('CustomInput',{staticClass:"mb-5",attrs:{"type":"textarea","rules":[],"label":"Intro Summary","name":"intro_summary","rows":"6","multiline":"","dark":"","outlined":""},on:{"input":_vm.input},model:{value:(_vm.formValues.intro_summary),callback:function ($$v) {_vm.$set(_vm.formValues, "intro_summary", $$v)},expression:"formValues.intro_summary"}})],1),_c(VCol,{attrs:{"cols":"12","md":"12"}},[_c('CustomInput',{staticClass:"mb-5",attrs:{"rules":[],"label":"Bio","name":"bio","type":"textarea","dark":"","outlined":""},on:{"input":_vm.input},model:{value:(_vm.formValues.bio),callback:function ($$v) {_vm.$set(_vm.formValues, "bio", $$v)},expression:"formValues.bio"}})],1),_c(VCol,{staticClass:"mb-8",attrs:{"cols":"12","md":"12"}},[_c('div',{staticClass:"text-uppercase text-left font-weight-bold"},[_vm._v("Bio Links")]),_c('CustomInput',{attrs:{"rules":[],"label":"Type here","name":"bio_links","type":"tag-input","dark":""},on:{"input":_vm.input},model:{value:(_vm.formValues.bio_links),callback:function ($$v) {_vm.$set(_vm.formValues, "bio_links", $$v)},expression:"formValues.bio_links"}}),(
            !_vm.formValues.bio_links.tags || !_vm.formValues.bio_links.tags.length
          )?_c('div',[_vm._v(" No bio links added ")]):_vm._e()],1),_c(VCol,{staticClass:"mb-8",attrs:{"cols":"12","md":"12"}},[_c('div',{staticClass:"text-uppercase text-left font-weight-bold"},[_vm._v(" CV "),_c('span',{staticClass:"grey--text"},[_vm._v("(Max 10MB, PDF)")])]),_c('CustomInput',{staticClass:"ml-2 text-left",attrs:{"type":"file","name":"cv","dark":""},on:{"clearFile":_vm.clearFile,"input":_vm.input},model:{value:(_vm.formValues.cv_file),callback:function ($$v) {_vm.$set(_vm.formValues, "cv_file", $$v)},expression:"formValues.cv_file"}})],1),_c(VCol,{staticClass:"mb-8",attrs:{"cols":"12","md":"12"}},[_c('div',{staticClass:"text-left mb-5 font-weight-bold"},[_vm._v(" Upload videos and photos to increase your social engagement and being noticed. ")]),_c('div',{staticClass:"text-left"},[_vm._v("Intro Video (Optional)")]),_c('div',{staticClass:"d-flex",staticStyle:{"overflow":"auto"}},[_vm._l((_vm.formValues.intro_video_files),function(video,index){return _c('div',{key:index},[(video)?_c('CustomInput',{staticClass:"text-left me-2",attrs:{"name":"intro_video","id":("intro_video-" + index),"type":"file","rules":[],"config":{
                accept: 'video/*'
              },"showFileName":false,"clearMediaConfirmation":"","dark":"","outlined":""},on:{"clearFile":function($event){return _vm.clearGalleryFile(
                  'intro_video_files',
                  video,
                  'intro_video',
                  index
                )},"input":_vm.input},model:{value:(_vm.formValues.intro_video_files[index]),callback:function ($$v) {_vm.$set(_vm.formValues.intro_video_files, index, $$v)},expression:"formValues.intro_video_files[index]"}}):_vm._e()],1)}),(_vm.formValues.intro_video_files.length < 3)?_c('div',[_c('CustomInput',{staticClass:"text-left",attrs:{"name":"intro_video_files","rules":[],"type":"file","config":{
                accept: 'video/*'
              },"dark":"","outlined":""},on:{"input":function($event){return _vm.checkFileSize($event, 'intro_video_files')}},model:{value:(
                _vm.formValues.intro_video_files[
                  _vm.formValues.intro_video_files.length
                ]
              ),callback:function ($$v) {_vm.$set(_vm.formValues.intro_video_files, 
                  _vm.formValues.intro_video_files.length
                , $$v)},expression:"\n                formValues.intro_video_files[\n                  formValues.intro_video_files.length\n                ]\n              "}})],1):_vm._e()],2)]),_c(VCol,{staticClass:"mb-8",attrs:{"cols":"12","md":"12"}},[_c('div',{staticClass:"text-left"},[_vm._v("Gallery")]),_c('div',{staticClass:"d-flex",staticStyle:{"overflow":"auto"}},[_vm._l((_vm.formValues.gallery_file),function(photo,index){return _c('div',{key:index,staticClass:"me-2"},[(photo)?_c('CustomInput',{staticClass:"text-left",attrs:{"name":"gallery_file","type":"file","config":{
                accept: 'image/*'
              },"showFileName":false,"clearMediaConfirmation":"","dark":"","outlined":""},on:{"clearFile":function($event){return _vm.clearGalleryFile('gallery_file', photo, 'gallery', index)},"input":_vm.input},model:{value:(_vm.formValues.gallery_file[index]),callback:function ($$v) {_vm.$set(_vm.formValues.gallery_file, index, $$v)},expression:"formValues.gallery_file[index]"}}):_vm._e()],1)}),(_vm.formValues.gallery_file.length < 5)?_c('div',[_c('CustomInput',{staticClass:"text-left",attrs:{"name":"gallery_file","type":"file","config":{
                accept: 'image/*'
              },"dark":"","outlined":""},on:{"input":_vm.input},model:{value:(
                _vm.formValues.gallery_file[_vm.formValues.gallery_file.length || 0]
              ),callback:function ($$v) {_vm.$set(_vm.formValues.gallery_file, _vm.formValues.gallery_file.length || 0, $$v)},expression:"\n                formValues.gallery_file[formValues.gallery_file.length || 0]\n              "}})],1):_vm._e()],2)]),_vm._l((_vm.formConfig),function(form,index){return _c(VCol,{key:index,attrs:{"cols":"12"}},[_c('div',{staticClass:"mb-4"},[(form.title)?_c('div',{staticClass:"text-uppercase text-left mb-5 font-weight-bold"},[_vm._v(" "+_vm._s(form.title)+" ")]):_vm._e(),(form.title)?_c(VRow,{attrs:{"no-gutters":""}},_vm._l((form.fields),function(field,idx){return _c(VCol,{key:idx,staticClass:"pr-5",class:_vm.$isMobile() ? 'mb-2' : '',attrs:{"cols":"12","md":"6"}},[_c('CustomInput',{staticClass:"mb-4",attrs:{"rules":[],"id":field.id,"name":field.id,"label":field.label,"placeholder":field.label,"type":"select","config":{
                  items: _vm.rate
                },"outlined":"","dark":""},on:{"input":_vm.input},model:{value:(_vm.formValues[field.id]),callback:function ($$v) {_vm.$set(_vm.formValues, field.id, $$v)},expression:"formValues[field.id]"}})],1)}),1):_c(VRow,{attrs:{"no-gutters":""}},[_vm._l((form.fields),function(field,i){return [(!field.is_skill)?_c(VCol,{key:i,staticClass:"pr-5 mb-4",attrs:{"cols":"12"}},[_c('CustomInput',_vm._b({attrs:{"rules":[],"name":field.id,"outlined":"","dark":""},on:{"input":_vm.input},model:{value:(_vm.formValues[field.id]),callback:function ($$v) {_vm.$set(_vm.formValues, field.id, $$v)},expression:"formValues[field.id]"}},'CustomInput',field,false))],1):_c(VRow,{key:i,staticClass:"mb-4",attrs:{"no-gutters":""}},[_c(VCol,{staticClass:"pr-5",class:_vm.$isMobile() ? 'mb-2' : '',attrs:{"cols":"12","md":"6"}},[_c('CustomInput',_vm._b({attrs:{"rules":[],"name":field.id,"outlined":"","dark":""},on:{"input":_vm.input},model:{value:(_vm.formValues[field.id]),callback:function ($$v) {_vm.$set(_vm.formValues, field.id, $$v)},expression:"formValues[field.id]"}},'CustomInput',field,false))],1),(_vm.hasOtherSkill(field.id))?_c(VCol,{staticClass:"pr-5",attrs:{"cols":"12","md":"6"}},[_c('CustomInput',{attrs:{"rules":[],"placeholder":_vm.$t('label.pleaseSpecify'),"label":_vm.$t('label.pleaseSpecify'),"type":"text","name":field.name,"dark":""},on:{"input":_vm.input},model:{value:(_vm.formValues[field.name]),callback:function ($$v) {_vm.$set(_vm.formValues, field.name, $$v)},expression:"formValues[field.name]"}})],1):_vm._e()],1)]})],2)],1)])})],2)],1),_c(VRow,{staticClass:"text-center mt-12 pb-2",attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"12","md":"12"}},[_c(VBtn,{staticClass:"btn1 mb-3",attrs:{"disabled":!_vm.valid},on:{"click":_vm.save}},[_vm._v(" Save & Next ")])],1),_c(VCol,{attrs:{"cols":"12","md":"12"}},[_c('div',{staticClass:"underlineBtn mb-3",on:{"click":function($event){return _vm.$emit('doItLater')}}},[_vm._v(" Do it later ")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }