<template>
  <div>
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      class="companyProfileForm w-100"
    >
      <v-row no-gutters justify="center" class="w-100">
        <v-col cols="12" md="12" class="mb-10">
          <CustomInput
            type="avatar"
            :rules="[]"
            v-model="formValues.headshot_file"
            label="Profile Photo*"
            name="headshot_file"
            :config="{
              accept: 'image/*'
            }"
            :showFileName="false"
            clearMediaConfirmation
            @clearFile="clearFile"
            @input="input"
          />
        </v-col>
        <v-col cols="12" md="12">
          <CustomInput
            type="textarea"
            :rules="[]"
            v-model="formValues.intro_summary"
            label="Intro Summary"
            name="intro_summary"
            rows="6"
            multiline
            dark
            outlined
            @input="input"
            class="mb-5"
          />
        </v-col>
        <v-col cols="12" md="12">
          <CustomInput
            v-model="formValues.bio"
            :rules="[]"
            label="Bio"
            name="bio"
            type="textarea"
            dark
            outlined
            @input="input"
            class="mb-5"
          />
        </v-col>
        <v-col cols="12" md="12" class="mb-8">
          <div class="text-uppercase text-left font-weight-bold">Bio Links</div>
          <CustomInput
            v-model="formValues.bio_links"
            :rules="[]"
            label="Type here"
            name="bio_links"
            type="tag-input"
            dark
            @input="input"
          />
          <div
            v-if="
              !formValues.bio_links.tags || !formValues.bio_links.tags.length
            "
          >
            No bio links added
          </div>
        </v-col>
        <v-col cols="12" md="12" class="mb-8">
          <div class="text-uppercase text-left font-weight-bold">
            CV <span class="grey--text">(Max 10MB, PDF)</span>
          </div>
          <CustomInput
            class="ml-2 text-left"
            v-model="formValues.cv_file"
            type="file"
            name="cv"
            dark
            @clearFile="clearFile"
            @input="input"
          />
        </v-col>
        <v-col cols="12" md="12" class="mb-8">
          <div class="text-left mb-5 font-weight-bold">
            Upload videos and photos to increase your social engagement and
            being noticed.
          </div>
          <div class="text-left">Intro Video (Optional)</div>
          <div class="d-flex" style="overflow: auto">
            <div
              v-for="(video, index) in formValues.intro_video_files"
              :key="index"
            >
              <CustomInput
                v-if="video"
                name="intro_video"
                :id="`intro_video-${index}`"
                class="text-left me-2"
                type="file"
                :rules="[]"
                :config="{
                  accept: 'video/*'
                }"
                :showFileName="false"
                clearMediaConfirmation
                v-model="formValues.intro_video_files[index]"
                @clearFile="
                  clearGalleryFile(
                    'intro_video_files',
                    video,
                    'intro_video',
                    index
                  )
                "
                dark
                outlined
                @input="input"
              />
            </div>
            <div v-if="formValues.intro_video_files.length < 3">
              <CustomInput
                class="text-left"
                name="intro_video_files"
                :rules="[]"
                type="file"
                :config="{
                  accept: 'video/*'
                }"
                v-model="
                  formValues.intro_video_files[
                    formValues.intro_video_files.length
                  ]
                "
                dark
                outlined
                @input="checkFileSize($event, 'intro_video_files')"
              />
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="12" class="mb-8">
          <div class="text-left">Gallery</div>
          <div class="d-flex" style="overflow: auto">
            <div
              v-for="(photo, index) in formValues.gallery_file"
              :key="index"
              class="me-2"
            >
              <CustomInput
                name="gallery_file"
                class="text-left"
                type="file"
                :config="{
                  accept: 'image/*'
                }"
                v-if="photo"
                v-model="formValues.gallery_file[index]"
                @clearFile="
                  clearGalleryFile('gallery_file', photo, 'gallery', index)
                "
                :showFileName="false"
                clearMediaConfirmation
                dark
                outlined
                @input="input"
              />
            </div>
            <div v-if="formValues.gallery_file.length < 5">
              <CustomInput
                name="gallery_file"
                class="text-left"
                type="file"
                :config="{
                  accept: 'image/*'
                }"
                v-model="
                  formValues.gallery_file[formValues.gallery_file.length || 0]
                "
                dark
                outlined
                @input="input"
              />
            </div>
          </div>
        </v-col>
        <v-col v-for="(form, index) in formConfig" :key="index" cols="12">
          <div class="mb-4">
            <div
              v-if="form.title"
              class="text-uppercase text-left mb-5 font-weight-bold"
            >
              {{ form.title }}
            </div>
            <v-row no-gutters v-if="form.title">
              <v-col
                cols="12"
                md="6"
                v-for="(field, idx) in form.fields"
                :key="idx"
                class="pr-5"
                :class="$isMobile() ? 'mb-2' : ''"
              >
                <CustomInput
                  v-model="formValues[field.id]"
                  :rules="[]"
                  :id="field.id"
                  :name="field.id"
                  :label="field.label"
                  :placeholder="field.label"
                  type="select"
                  :config="{
                    items: rate
                  }"
                  outlined
                  dark
                  @input="input"
                  class="mb-4"
                />
              </v-col>
            </v-row>
            <v-row no-gutters v-else>
              <template v-for="(field, i) in form.fields">
                <v-col
                  v-if="!field.is_skill"
                  cols="12"
                  :key="i"
                  class="pr-5 mb-4"
                >
                  <CustomInput
                    v-model="formValues[field.id]"
                    v-bind="field"
                    :rules="[]"
                    :name="field.id"
                    outlined
                    dark
                    @input="input"
                  />
                </v-col>
                <v-row v-else :key="i" no-gutters class="mb-4">
                  <v-col
                    cols="12"
                    md="6"
                    class="pr-5"
                    :class="$isMobile() ? 'mb-2' : ''"
                  >
                    <CustomInput
                      v-model="formValues[field.id]"
                      v-bind="field"
                      :rules="[]"
                      :name="field.id"
                      outlined
                      dark
                      @input="input"
                    />
                  </v-col>
                  <v-col
                    v-if="hasOtherSkill(field.id)"
                    cols="12"
                    md="6"
                    class="pr-5"
                  >
                    <CustomInput
                      v-model="formValues[field.name]"
                      :rules="[]"
                      :placeholder="$t('label.pleaseSpecify')"
                      :label="$t('label.pleaseSpecify')"
                      type="text"
                      :name="field.name"
                      dark
                      @input="input"
                    />
                  </v-col>
                </v-row>
              </template>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-form>
    <v-row no-gutters class="text-center mt-12 pb-2">
      <v-col cols="12" md="12">
        <v-btn class="btn1 mb-3" @click="save" :disabled="!valid">
          Save & Next
        </v-btn>
      </v-col>
      <v-col cols="12" md="12">
        <div class="underlineBtn mb-3" @click="$emit('doItLater')">
          Do it later
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import { formValidationHelper } from '@/utils';
  import { PROFILE } from '@/api';
  import CustomInput from '@/components/inputs/custom-input.vue';
  import { mapGetters } from 'vuex';
  import { AUTH_ME } from '@/store/auth.module';
  import { listService } from '@/services';
  import uploadMediaMixin from '@/mixin/upload-media.mixin';

  export default {
    name: 'BioIntroInfo',
    mixins: [uploadMediaMixin],
    props: {
      userInfo: {
        type: Object,
        default: () => ({})
      },
      freelancerRole: {
        type: Boolean,
        default: false
      },
      isCreate: {
        type: Boolean,
        default: false
      },
      skills: {
        type: Array,
        default: () => []
      },
      secondary_skills: {
        type: Array,
        default: () => []
      }
    },
    components: {
      CustomInput
    },
    data: () => ({
      formValues: {
        intro_summary: null,
        bio: null,
        bio_links: {
          tags: []
        },
        cv_file: null,
        skills: [],
        skill_ids: [],
        secondary_skills: [],
        secondary_skill_ids: [],
        age_to_play: null,
        tv_online_rate: null,
        feature_film_rate: null,
        commercial_rate: null,
        variety_rate: null,
        student_project_rate: null,
        public_service_announcement_rate: null,
        corporate_rate: null,
        voiceover_rate: null,
        list_projects: null,
        gallery_file: [],
        intro_video_files: []
      },
      valid: false,
      formValidation: formValidationHelper,
      media: [
        {
          key: 'cv_file',
          mediaCollectionsName: 'cv'
        },
        {
          key: 'intro_video_files',
          mediaCollectionsName: 'intro_video'
        },
        {
          key: 'gallery_file',
          mediaCollectionsName: 'gallery'
        },
        {
          key: 'headshot_file',
          mediaCollectionsName: 'headshot'
        }
      ]
    }),
    computed: {
      ...mapGetters({
        rate: 'constants/rate',
        mediaCollections: 'constants/mediaCollections'
      }),
      otherSkillType() {
        return this.skills?.find((x) => x.type == 0)?.value;
      },
      includeOtherSkill() {
        return this.formValues.skill_ids.includes(this.otherSkillType);
      },
      formConfig() {
        return this.freelancerRole
          ? [
              {
                fields: [
                  {
                    id: 'list_projects',
                    name: 'list_projects',
                    placeholder: 'Notable Projects',
                    label: 'Notable Projects',
                    type: 'textarea'
                  }
                ]
              }
            ]
          : [
              {
                fields: [
                  {
                    id: 'skill_ids',
                    name: 'skills',
                    placeholder: 'Skills',
                    label: 'Primary Skills',
                    type: 'select',
                    is_skill: true,
                    config: {
                      multiple: true,
                      hideDetails: true,
                      items: listService.getDynamicList(
                        this.skills,
                        false,
                        'type'
                      )
                    }
                  }
                ]
              },
              {
                fields: [
                  {
                    id: 'secondary_skill_ids',
                    name: 'secondary_skills',
                    placeholder: 'Skills',
                    label: 'Secondary Skills',
                    type: 'select',
                    is_skill: true,
                    config: {
                      multiple: true,
                      hideDetails: true,
                      items: listService.getDynamicList(
                        this.skills,
                        false,
                        'type'
                      )
                    }
                  },
                  {
                    id: 'age_to_play',
                    name: 'age_to_play',
                    placeholder: 'On-Screen Age',
                    label: 'On-Screen Age',
                    type: 'text'
                  }
                ]
              },
              {
                title: 'Rate Per Day',
                fields: [
                  {
                    id: 'tv_online_rate',
                    label: 'TV Online Rate'
                  },
                  {
                    id: 'feature_film_rate',
                    label: 'Feature Film Rate'
                  },
                  {
                    id: 'commercial_rate',
                    label: 'Commercial Rate'
                  },
                  {
                    id: 'variety_rate',
                    label: 'Variety Rate'
                  },
                  {
                    id: 'student_project_rate',
                    label: 'Student Project Rate'
                  },
                  {
                    id: 'public_service_announcement_rate',
                    label: 'Public Service Announcement Rate'
                  }
                ]
              },
              {
                title: 'Rate Per Hour',
                fields: [
                  {
                    id: 'voiceover_rate',
                    label: 'Voiceover Rate'
                  }
                ]
              }
            ];
      }
    },
    methods: {
      hasOtherSkill(id) {
        return this.formValues[id]?.includes(
          this.skills?.find((x) => x.type == 0)?.value
        );
      },
      fileSelect() {
        this.$refs.profilePicInput.click();
      },
      async clearGalleryFile(type, data, media, index) {
        await this.clearFile(data);
        this.formValues[type].splice(index, 1);
        if (data.id) {
          let arr = [];
          const mediaTypeId = this.mediaCollections.indexOf(
            `profiles.${media}`
          );
          this.formValues[type].forEach((item, index) => {
            arr.push([item.id, index + 1]);
          });
          await PROFILE.sortFile(
            this.userInfo?.profile?.profile_id,
            {
              images: {
                ...Object.fromEntries(arr)
              }
            },
            mediaTypeId
          );
        }
      },
      async clearFile(data) {
        if (data?.id) {
          this.$startLoading();
          try {
            await PROFILE.removeFile(data.id);
            await this.$store.dispatch(AUTH_ME);
            this.$closeModal();
          } catch (e) {
            this.openAppDialogInfo(
              'error',
              e.error_message || 'Request Failed',
              '',
              [
                {
                  text: 'OK',
                  action: () => {
                    this.closeAppDialogInfo();
                  }
                }
              ]
            );
          }
          this.$stopLoading();
        }
      },
      async save() {
        const openAppDialogInfo = (e, isVideo = false) => {
          let errMsg = isVideo
            ? 'Upload Failed, Please check your internet connection and try again'
            : 'Request Failed';
          this.openAppDialogInfo('error', e.error_message || errMsg, '', [
            {
              text: 'OK',
              action: () => {
                this.closeAppDialogInfo();
              }
            }
          ]);
        };
        this.$startLoading();

        try {
          if (!this.isCreate) {
            await PROFILE.updateBioIntroInfo(
              this.formValues,
              this.freelancerRole
            ).then(async () => {
              const singleFileKey = ['cv', 'headshot'];
              await Promise.all(
                this.media.map(async (m) => {
                  const mediaId = this.mediaCollections.indexOf(
                    `profiles.${m.mediaCollectionsName}`
                  );
                  if (
                    singleFileKey.includes(m.mediaCollectionsName) &&
                    this.formValues[m.key] &&
                    !this.formValues[m.key]?.id
                  ) {
                    let data = {
                      file: this.formValues[m.key].file
                    };
                    await PROFILE.uploadFile(
                      this.userInfo?.profile?.profile_id,
                      data,
                      mediaId
                    ).catch((e) => {
                      this.formValues[m.key] = null;
                      openAppDialogInfo(e);
                    });
                  } else if (!singleFileKey.includes(m.mediaCollectionsName)) {
                    if ([2, 5].includes(mediaId)) {
                      let values = this.formValues[m.key];
                      this.$pushVideoToCompress({
                        file: values,
                        extra: {
                          profileId: this.userInfo?.profile?.profile_id,
                          mediaId
                        }
                      });
                      // await this.processValue(
                      //   values,
                      //   {
                      //     profileId: this.userInfo?.profile?.profile_id,
                      //     mediaId
                      //   }
                      // )
                    } else {
                      this.formValues[m.key].map(async (item, idx) => {
                        if (!item || item.id) return;
                        let tempData = {
                          file: item.file,
                          seq: idx + 1
                        };
                        return await PROFILE.uploadFile(
                          this.userInfo?.profile?.profile_id,
                          tempData,
                          mediaId
                        ).catch((e) => {
                          openAppDialogInfo(e, [2, 5].includes(mediaId));
                        });
                      });
                    }
                  }
                })
              );
              this.next();
            });
          } else this.next();
          this.$stopLoading();
        } catch (e) {
          openAppDialogInfo(e);
        }
      },
      async uploadFile(item, id, index) {
        this.$startLoading();
        try {
          let data = {
            file: item.file
          };
          if (index != -1) {
            data.seq = index + 1;
          }
          await PROFILE.uploadFile(
            this.userInfo?.profile?.profile_id,
            data,
            id
          );
        } catch (e) {
          this.openAppDialogInfo(
            'error',
            e.error_message || 'Request Failed',
            '',
            [
              {
                text: 'OK',
                action: () => {
                  this.closeAppDialogInfo();
                }
              }
            ]
          );
        }
        this.$stopLoading();
      },
      checkFileSize(e, name) {
        if (e?.file?.size > 524288000) {
          if (['intro_video_files'].includes(name))
            this.formValues[name][this.formValues[name].length - 1] = null;
          else this.formValues[name] = null;

          this.openAppDialogInfo(
            'error',
            'Video file size must not be greater than 500MB.',
            '',
            [
              {
                text: 'OK',
                action: () => {
                  this.closeAppDialogInfo();
                }
              }
            ]
          );
        }
        this.input();
      },
      next() {
        this.$emit('next', this.formValues);
      },
      input() {
        this.$emit('input', this.formValues);
      }
    },
    mounted() {
      this.formValues = {
        ...this.userInfo.profile,
        bio_links: {
          tags: this.userInfo?.profile?.bio_links || [],
          text: ''
        },
        gallery_file: this.userInfo?.profile?.gallery_file || [],
        intro_video_files: this.userInfo?.profile?.intro_video_files || [],
        skill_ids:
          this.userInfo?.profile?.skill_ids?.map((x) => {
            return Number(x);
          }) || [],
        secondary_skill_ids:
          this.userInfo?.profile?.secondary_skill_ids?.map((x) => {
            return Number(x);
          }) || []
      };
    }
  };
</script>

<style></style>
