<template>
  <div>
    <div class="mb-5 text-left md-font">Social Platform (Optional)</div>
    <v-form
      ref="form"
      lazy-validation
      v-model="valid"
      class="socialPlatformForm w-100"
    >
      <v-row no-gutters class="w-100">
        <v-col
          cols="12"
          md="6"
          v-for="(form, index) in socialPlatforms"
          :key="index"
        >
          <div class="px-2">
            <AppFormField
              v-model="formValues[form.key]"
              :name="`form_${form.key}`"
              :id="`form_${form.key}`"
              :label="form.label"
              outlined
              dark
              @input="
                checkInputUrl(form.key);
                input();
              "
            >
              <template v-slot:prepend-inner>
                <span class="grey--text">https://</span>
              </template>
            </AppFormField>
          </div>
        </v-col>
      </v-row>
    </v-form>
    <v-row no-gutters class="text-center mt-12 pb-2">
      <v-col cols="12" md="12">
        <v-btn class="btn1" @click="save" :disabled="!valid"> Go Live </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import { PROFILE } from '@/api';

  export default {
    name: 'SocialPlatform',
    props: {
      userInfo: {
        type: Object,
        default: () => ({})
      },
      isCreate: {
        type: Boolean,
        default: false
      }
    },
    data: () => ({
      formValues: {
        website_link: null,
        facebook: null,
        instagram: null,
        twitter: null,
        linkedin: null,
        tiktok: null,
        twitch: null
      },
      socialPlatforms: [
        {
          key: 'website_link',
          label: 'Website'
        },
        {
          key: 'facebook',
          label: 'Facebook'
        },
        {
          key: 'instagram',
          label: 'Instagram'
        },
        {
          key: 'twitter',
          label: 'Twitter'
        },
        {
          key: 'linkedin',
          label: 'LinkedIn'
        },
        {
          key: 'youtube',
          label: 'Youtube'
        },
        {
          key: 'tiktok',
          label: 'Tiktok'
        },
        {
          key: 'twitch',
          label: 'Twitch'
        }
      ],
      valid: false
    }),
    computed: {},
    methods: {
      async save() {
        this.$startLoading();

        try {
          let data = {};
          this.socialPlatforms.forEach((x) => {
            let value = this.formValues[x.key];
            if (
              value &&
              !(/^https?:\/\//i.test(value) || /^http?:\/\//i.test(value))
            ) {
              value = 'https://' + value;
            }
            data[x.key] = value;
          });
          if (!this.isCreate) {
            await PROFILE.updateSocialMedia(data);
          }
          this.$closeModal();
          this.next();
        } catch (e) {
          this.openAppDialogInfo(
            'error',
            e.error_message || 'Request Failed',
            '',
            [
              {
                text: 'OK',
                action: () => {
                  this.closeAppDialogInfo();
                }
              }
            ]
          );
        }
        this.$stopLoading();
      },
      checkInputUrl(key) {
        let value = this.formValues[key];
        if (
          value &&
          (/^https?:\/\//i.test(value) || /^http?:\/\//i.test(value))
        ) {
          value = value.replace(/^https?:\/\//i, '');
          value = value.replace(/^http?:\/\//i, '');
        }
        this.formValues[key] = value;
      },
      next() {
        this.$emit('next', this.formValues);
      },
      input() {
        this.$emit('input', this.formValues);
      }
    },
    mounted() {
      if (!this.isCreate) {
        this.socialPlatforms.forEach((x) => {
          let value = this.userInfo.profile[x.key];
          if (value) {
            value = value.replace(/^https?:\/\//i, '');
            value = value.replace(/^http?:\/\//i, '');
          }
          this.formValues[x.key] = value;
        });
      }
      // this.formValues = {
      //   ...this.userInfo.profile
      // }
    }
  };
</script>

<style scoped lang="scss">
  ::v-deep .v-input__prepend-inner {
    align-self: center !important;
    margin-top: 0 !important;
  }
</style>
