<template>
  <div>
    <div class="mb-5 text-left md-font">In-charge Person Info</div>
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      class="userInfoForm w-100 pb-12"
    >
      <v-row no-gutters class="w-100 pb-12">
        <v-col
          cols="12"
          md="6"
          v-for="(form, index) in userInfoFormFields"
          :key="index"
        >
          <div class="px-2">
            <AppFormField
              v-model="formValues[form.key]"
              :name="`form_${form.key}`"
              :label="$t(`label.${form.label}`)"
              :rules="form.rules"
              @input="input"
              outlined
              dark
            />
          </div>
        </v-col>
      </v-row>
    </v-form>
    <v-row no-gutters class="text-center">
      <v-col cols="12" md="12">
        <v-btn class="btn1 mb-3" @click="save" :disabled="!valid">
          Save & Next
        </v-btn>
      </v-col>
      <v-col cols="12" md="12">
        <v-btn
          class="btn1 outline mb-3"
          @click="$router.push('/change-password')"
        >
          Change Password
        </v-btn>
      </v-col>
      <v-col cols="12" md="12">
        <div class="underlineBtn mb-3" @click="$emit('doItLater')">
          Do it later
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import { formValidationHelper } from '@/utils';
  import { PROFILE } from '@/api';

  export default {
    name: 'UserInfo',
    props: {
      userInfo: {
        type: Object,
        default: () => ({})
      },
      isCreate: {
        type: Boolean,
        default: false
      }
    },
    data: () => ({
      formValues: {
        first_name: '',
        last_name: ''
      },
      valid: false
    }),
    computed: {
      userInfoFormFields() {
        return [
          {
            key: 'first_name',
            label: 'firstName',
            rules: formValidationHelper.requiredRules()
          },
          {
            key: 'last_name',
            label: 'lastName',
            rules: formValidationHelper.requiredRules()
          }
        ];
      }
    },
    methods: {
      async save() {
        this.$startLoading();

        try {
          if (!this.isCreate) {
            await PROFILE.updateUserInfo(this.formValues);
          }
          this.$closeModal();
          this.next();
        } catch (e) {
          this.openAppDialogInfo(
            'error',
            e.error_message || 'Request Failed',
            '',
            [
              {
                text: 'OK',
                action: () => {
                  this.closeAppDialogInfo();
                }
              }
            ]
          );
        }
        this.$stopLoading();
      },
      next() {
        this.$emit('next', this.formValues);
      },
      input() {
        this.$emit('input', this.formValues);
      }
    },
    mounted() {
      this.formValues = {
        first_name: this.userInfo.first_name,
        last_name: this.userInfo.last_name
      };
    }
  };
</script>

<style scoped lang="scss">
  ::v-deep .btn1 {
    padding: 25px 50px !important;
  }
</style>
