<template>
  <div>
    <div class="mb-5 text-left md-font">Personal Info</div>
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      class="businessInfoForm w-100"
    >
      <v-row no-gutters class="w-100">
        <v-col
          cols="12"
          :md="form.md || 6"
          v-for="(form, index) in businessInfoFormFields"
          :key="index"
          class="align-self-center"
        >
          <div class="px-2">
            <AppFormField
              v-if="form.field !== 'other'"
              v-model="formValues[form.key]"
              :name="`form_${form.key}`"
              :label="$t(`label.${form.label}`)"
              :rules="form.rules"
              :type="form.formType"
              :items="form.options"
              :multiple="form.multiple"
              :disabled="form.disabled"
              itemColor="background"
              :color="form.color || 'surface'"
              outlined
              dark
              @input="input"
            />
            <AppFormField
              v-if="
                form.field === 'other' &&
                (form.key === 'gender_other' ||
                  form.key === 'pronouns_other') &&
                isOther[form.key]
              "
              v-model="formValues[form.key]"
              :name="`form_${form.key}`"
              :label="$t(`label.${form.label}`)"
              :rules="form.rules"
              :type="form.formType"
              itemColor="background"
              :color="form.color || 'surface'"
              dark
              @input="input"
            />
            <vue-tel-input-vuetify
              v-if="form.key === 'mobile'"
              :preferredCountries="['SG', 'MY']"
              :defaultCountry="
                $get(formValues, 'mobileDialCodeCountry.abbr', 'SG')
              "
              v-model="formValues.mobile"
              :label="$t('label.mobileNo')"
              mode=""
              required
              outlined
              dark
              input
              disabled
              @input="onNumberChange"
              @country-changed="updateCountry"
            ></vue-tel-input-vuetify>
            <div class="text-left px-2" v-if="form.key === 'gender'">
              <div>{{ $t('label.gender') }}</div>
              <v-radio-group
                v-model="formValues[form.key]"
                row
                class="d-inline-block"
              >
                <v-radio
                  :value="gender.value"
                  dark
                  color="#FF2FF2"
                  v-for="(gender, genderIndex) in constantsData.gender"
                  :key="genderIndex"
                >
                  <template v-slot:label>
                    <span class="white--text">{{ gender.description }}</span>
                  </template>
                </v-radio>
              </v-radio-group>
            </div>
            <div
              class="text-left px-2 pb-6"
              v-if="form.key === 'languages_spoken'"
            >
              <div>{{ $t('label.languageSpoken') }}</div>
              <div class="d-flex" style="overflow-x: auto">
                <v-checkbox
                  v-for="(language, index) in constantsData.language_spoken"
                  :key="index"
                  v-model="formValues[form.key]"
                  :label="language.description"
                  :value="language.value"
                  dark
                  color="primary"
                  hide-details
                  class="pe-4"
                  multiple
                  @click="input"
                ></v-checkbox>
              </div>
            </div>
            <div class="text-left px-2 pb-6" v-if="form.key == 'skin_color'">
              <div>{{ $t('label.skinColor') }}</div>
              <div class="row mt-1">
                <div
                  class="col-auto"
                  v-for="color in form.options"
                  :key="color.value"
                >
                  <div
                    class="skinColorCard text-center"
                    :class="
                      formValues.skin_color == color.value ? 'selected' : ''
                    "
                    @click="
                      formValues.skin_color = color.value;
                      input();
                    "
                  >
                    <div
                      class="colorCard"
                      :style="{
                        'background-color': `#${color.text}`
                      }"
                    ></div>
                    <div class="mt-2">
                      {{ color.value }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-form>
    <v-row no-gutters class="text-center mt-12 pb-2">
      <v-col cols="12" md="12">
        <v-btn class="btn1 mb-3" @click="save"> Save & Next </v-btn>
      </v-col>
      <v-col cols="12" md="12">
        <div class="underlineBtn mb-3" @click="$emit('doItLater')">
          Do it later
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import { formValidationHelper, uiHelper, i18nHelper } from '@/utils';
  import {
    PROFILE_UPDATE_BIO_INFO,
    PROFILE_INITIAL_UPDATE_BIO_INFO_STATE
  } from '@/store/profile.module';
  import { listService, i18nService } from '@/services';
  import { LOCAL, SHARED } from '@/constants';
  import constantsCountry from '@/filters/constants-country.filter';

  export default {
    name: 'BioInfo',
    props: {
      userInfo: {
        type: Object,
        default: () => ({})
      },
      isCreate: {
        type: Boolean,
        default: false
      },
      freelancerRole: {
        type: Boolean,
        default: false
      }
    },
    data: () => ({
      formValues: {
        // gender: null,
        // genderOther: null,
        // pronouns: [],
        // pronounsOther: null,
        // countryId: [],
        // email: null,
        // dob: null,
        // mobile: null,
        // mobileDialCode: '+60',
        // displayName: null,
        // rates: null,
        // ethnicity: null,
        // race: null,
        // languagesSpoken: [],
        // height: null,
        // weight: null,
        // hair_color: null,
        // hairLength: null,
        // hairType: null,
        // eyeColor: null,
        // skinColor: null,
      },
      valid: false,
      currentLanguage: i18nService.getActiveLanguage(),
      formValidation: formValidationHelper
    }),
    computed: {
      updateBioInfoComplete() {
        return this.$store.state.profile.updateBioInfo.complete;
      },
      constantsData() {
        let constants = uiHelper.getLocalStorage(LOCAL.CONSTANTS);

        return constants
          ? JSON.parse(constants)
          : this.$store.state.constants.constants.data;
      },
      businessInfoFormFields() {
        const talentFields = this.freelancerRole
          ? []
          : [
              {
                key: 'race',
                label: 'race',
                formType: 'select',
                clearable: true,
                options: listService.getDynamicList(this.constantsData.race)
              },
              {
                key: 'languages_spoken',
                label: 'languagesSpoken',
                field: 'other',
                md: 12
              },
              {
                key: 'height',
                label: 'heightCm'
              },
              {
                key: 'weight',
                label: 'weightKg'
              },
              {
                key: 'hair_color',
                label: 'hairColor',
                formType: 'select',
                clearable: true,
                options: listService.getDynamicList(
                  this.constantsData.hair_color
                )
              },
              {
                key: 'hair_length',
                label: 'hairLength',
                formType: 'select',
                clearable: true,
                options: listService.getDynamicList(
                  this.constantsData.hair_length
                )
              },
              {
                key: 'hair_type',
                label: 'hairType',
                formType: 'select',
                clearable: true,
                options: listService.getDynamicList(
                  this.constantsData.hair_type
                )
              },
              {
                key: 'eye_color',
                label: 'eyeColor',
                formType: 'select',
                clearable: true,
                options: listService.getDynamicList(
                  this.constantsData.eye_color
                )
              },
              {
                key: 'skin_color',
                label: 'skinColor',
                field: 'other',
                options: this.$store.getters['constants/skin_color'],
                md: 12
              }
            ];
        return [
          {
            key: 'display_name',
            label: 'displayName',
            rules: formValidationHelper.requiredRules()
          },
          {
            field: 'other'
          },
          {
            key: 'gender',
            label: 'sex',
            field: 'other',
            rules: formValidationHelper.requiredRules()
          },
          {
            key: 'gender_other',
            label: 'pleaseSpecify',
            field: 'other',
            rules: formValidationHelper.requiredRules()
          },
          {
            key: 'pronouns',
            label: 'pronouns',
            formType: 'select',
            options: listService.getDynamicList(this.constantsData.pronouns),
            rules: formValidationHelper.requiredRules(),
            multiple: true
          },
          {
            key: 'pronouns_other',
            label: 'pleaseSpecify',
            field: 'other',
            rules: formValidationHelper.requiredRules()
          },
          {
            key: 'dob',
            label: 'dateOfBirth',
            formType: 'date',
            color: 'title',
            rules: formValidationHelper.dateRules(
              0,
              SHARED.DATE_TODAY,
              i18nHelper.getMessage('label.dateOfBirth')
            )
          },
          {
            key: 'mobile',
            label: 'mobileNo',
            field: 'other',
            disabled: true
          },
          {
            key: 'email',
            label: 'email',
            rules:
              this.userInfo?.register_method == 'email'
                ? formValidationHelper
                    .emailRules()
                    .concat(formValidationHelper.requiredRules())
                : formValidationHelper.emailRules()
          },
          {
            key: 'country_id',
            label: 'countryOfResidence',
            formType: 'select',
            options: listService.getCountryList(
              this.constantsData.all_country,
              this.currentLanguage
            ),
            rules: formValidationHelper.requiredRules(),
            multiple: true
          },
          ...talentFields
        ];
      },
      phoneCodes() {
        return listService.getPhoneCodeList(this.constantsData.country);
      },
      isOther() {
        const genderOtherValueKey = this.constantsData.gender.find(
          (x) => x.key === 'OTHER'
        ).value;
        const pronounsOtherValueKey = this.constantsData.pronouns.find(
          (x) => x.key === 'OTHER'
        ).value;
        return {
          gender_other: this.formValues.gender === genderOtherValueKey,
          pronouns_other: this.formValues.pronouns?.includes(
            pronounsOtherValueKey
          )
        };
      }
    },
    watch: {
      isOtherDescription(val) {
        if (!val) this.formValues.descriptionOther = null;
      },
      updateBioInfoComplete() {
        let response = this.$store.state.profile.updateBioInfo;
        if (response.complete) {
          this.initialUpdateBioInfoState();
        }
      }
    },
    methods: {
      onNumberChange(v, v2) {
        this.formValues.mobile = v2.number.significant;
        this.$emit('input', this.formValues);
      },
      updateCountry(event) {
        this.formValues.mobile_dial_code = '+' + event.dialCode;
        this.formValues.mobileDialCodeCountry = constantsCountry(
          this.formValues.mobile_dial_code,
          'getConstantsByPhoneCode'
        );
        this.$emit('input', this.formValues);
      },
      async save() {
        const formValidate = this.$refs['form'].validate();
        if (formValidate === true) {
          await this.$store.dispatch(PROFILE_UPDATE_BIO_INFO, {
            data: this.formValues
          });
        }
      },
      initialUpdateBioInfoState() {
        this.$emit('next', this.formValues);
        this.$store.dispatch(PROFILE_INITIAL_UPDATE_BIO_INFO_STATE);
      },
      input() {
        this.$emit('input', this.formValues);
      }
    },
    mounted() {
      const findVal = (key, val) => {
        return this.constantsData[key].find((x) => x.description === val)
          ?.value;
      };

      this.formValues = {
        ...this.formValues,
        gender: this.isCreate
          ? this.userInfo.gender
          : findVal('gender', this.userInfo.gender),
        gender_other: this.userInfo.gender_other,
        pronouns: this.userInfo.pronouns,
        pronouns_other: this.userInfo.pronouns_other,
        country_id: this.userInfo.country_id,
        email: this.userInfo.email,
        dob: this.userInfo.dob,
        mobile: this.userInfo.mobile,
        mobile_dial_code: this.userInfo.mobile_dial_code || '+65',
        mobileDialCodeCountry: constantsCountry(
          this.userInfo.mobile_dial_code || '+65',
          'getConstantsByPhoneCode'
        ),
        display_name:
          this.userInfo.profile?.display_name || this.userInfo?.display_name
      };

      if (!this.freelancerRole) {
        this.formValues = {
          ...this.formValues,
          rates: null,
          ethnicity: null,
          race: this.userInfo?.race || this.userInfo.profile?.race || null,
          languages_spoken:
            this.userInfo?.languages_spoken ||
            this.userInfo.profile?.languages_spoken ||
            [],
          height:
            this.userInfo?.height || this.userInfo.profile?.height || null,
          weight:
            this.userInfo?.weight || this.userInfo.profile?.weight || null,
          hair_color:
            this.userInfo?.hair_color ||
            this.userInfo.profile?.hair_color ||
            null,
          hair_length:
            this.userInfo?.hair_length ||
            this.userInfo.profile?.hair_length ||
            null,
          hair_type:
            this.userInfo?.hair_type ||
            this.userInfo.profile?.hair_type ||
            null,
          eye_color:
            this.userInfo?.eye_color ||
            this.userInfo.profile?.eye_color ||
            null,
          skin_color:
            this.userInfo?.skin_color ||
            this.userInfo.profile?.skin_color ||
            null
        };
      }
    }
  };
</script>

<style scoped lang="scss">
  .skinColorCard {
    cursor: pointer;
    border: 1px solid #242424;
    border-radius: 10px;
    padding: 15px 20px;

    &.selected {
      border: 2px solid #ff2ff2;
    }
    .colorCard {
      padding: 30px 30px;
    }
  }
</style>
